<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";

export default {
  page: {
    title: "Restaurant Menu Table",
    meta: [
      {
        name: "description",
        content: appConfig.description
      }
    ]
  },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      tableData: [],
      title: "Restaurant Menu ",
      items: [
        {
          text: "View"
        },
        {
          text: "Restaurant menu",
          active: true
        }
      ],
      restaurants: [],
      companyID: 0,
      branches: [],
      cityArr: [],
      city: "",
      // restaurantID:(sessionStorage.getItem('restID')>0)? [{'restID':sessionStorage.getItem('restID'),'name':sessionStorage.getItem('restName')}] : "",
      // branchID:(sessionStorage.getItem('branchID')>0) ? [{'restBranchID':sessionStorage.getItem('branchID'),'branchName':sessionStorage.getItem('branchName')}] : "",
      restaurantID: "",
      restBranchID: "",
      branchID: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100, 500, 1000, 2000, "All"],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        {
          key: "Action",
          sortable: false
        },
        {
          key: "title",
          sortable: false
        },
        {
          key: "categoryName",
          sortable: true
        },
        {
          key: "price",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "calories",
          sortable: true
        },
        {
          key: "foodType",
          sortable: true
        },
        {
          key: "mealName",
          sortable: true
        },
        {
          key: "created",
          sortable: true
        }
      ],
      path: "",
      editRight: "",
      deleteRight: ""
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.readRestaurantMenu();
    this.getRestaurantList();
    this.checkUserRights();
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    checkUserRights() {
      this.path = this.$route.path;
      this.axios
        .post(this.$loggedRole+"/checkUserRights", {
          empID: this.$storageData.profile.pid,
          empTypeID: this.$storageData.profile.empTypeID,
          path: this.path
        })
        .then(result => {
          this.editRight = result.data.data[0].isEdit;
          this.deleteRight = result.data.data[0].isDelete;
        });
    },

    downloadSample() {
      this.axios
        .post(this.$loggedRole+"/exportExcel",
          {
            exportData: this.tableData,
            page: "RestaurantMenu"
          },
          {
            responseType: "blob"
          }
        )
        .then(response => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel"
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "restaurant-menu.xlsx");
          document.body.appendChild(link);
          link.click();
        });
    },

    readRestaurantMenu() {
      this.axios
        .post(this.$loggedRole+"/viewRestaurantMenu",
          {
            restID: this.restaurantID.restID,
            branchID: this.restBranchID,
            empTypeID: this.$storageData.profile.empTypeID,
            city: this.$storageData.profile.city
          } //this.branchID.restBranchID}
        )
        .then(response => {
          //Then injecting the result to datatable parameters.

          this.tableData = response.data.data;
          //console.log((response));
        });
    },
    getRestaurantCities() {
      this.axios
        .post(this.$loggedRole+"/getRestaurantCities", {
          restID:
            this.$storageData.login_type == 1
              ? this.restaurantID.restID
              : this.$storageData.profile.restID,
          empTypeID: this.$storageData.profile.empTypeID,
          city: this.$storageData.profile.city
        })
        .then(response => {
          this.cityArr = response.data.data;
          this.city = this.cityArr ? this.cityArr[0] : "";
          this.readRestaurantBranchData();
        });
    },

    getRestaurantList() {
      var companyID = this.companyID;
      if (
        this.$storageData.login_type == 2 ||
        this.$storageData.profile.empTypeID == 11
      ) {
        companyID = this.$storageData.profile.companyID;
      }

      this.axios
        .post(this.$loggedRole+"/getRestaurantList", {
          companyID: companyID,
          restID:
            this.$storageData.login_type == 2 &&
            this.$storageData.profile.empTypeID != 11
              ? this.$storageData.profile.restID
              : 0,
          restBranchID:
            this.$storageData.login_type == 2 &&
            this.$storageData.profile.empTypeID != 11
              ? this.$storageData.profile.restBranchID
              : 0,
          empID: this.$storageData.profile.pid,
          empTypeID: this.$storageData.profile.empTypeID,
          city: this.$storageData.profile.city
        })
        .then(result => {
          this.restaurants = result.data.data;
          this.restaurantID = this.restaurants ? this.restaurants[0] : "";
          this.getRestaurantCities();
          //this.restBranchID = (this.$storageData.profile.empTypeID==2 || this.$storageData.profile.empTypeID==11) ? 0 : this.$storageData.profile.restBranchID;
        });
    },
    onChangeRestBrand() {
      this.readRestaurantBranchData();
      this.getRestaurantCities();
      //  if(this.restaurantID.restID>0){ /// for maintaining state of dropdown
      //    sessionStorage.setItem('restID',this.restaurantID.restID);
      //    sessionStorage.setItem('restName',this.restaurantID.name);
      // }
      // if(this.branchID.restBranchID>0){ /// for maintaining state of dropdown
      //    sessionStorage.setItem('branchID',this.branchID.restBranchID);
      //    sessionStorage.setItem('branchName',this.branchID.branchName);
      // }
    },
    onchangeBranch() {
      this.restBranchID = this.branchID ? this.branchID.restBranchID : "";
      this.readRestaurantMenu();
    },

    readRestaurantBranchData() {
      this.axios
        .post(this.$loggedRole+"/getRestaurantBranchByRestID", {
          restID: this.restaurantID.restID,
          restBranchID:
            this.$storageData.profile.empTypeID == 2 ||
            this.$storageData.profile.empTypeID == 11
              ? 0
              : this.$storageData.profile.restBranchID,
          empID: this.$storageData.profile.pid,
          empTypeID: this.$storageData.profile.empTypeID,
          city: this.city ? this.city.city : ""
        })
        .then(response => {
          this.branches = response.data.data;
          this.branchID = this.branches ? this.branches[0] : "";
          this.restBranchID = this.branchID ? this.branchID.restBranchID : "";
          this.readRestaurantMenu();
        });
    }
    // applyFilter(){
    //   this.readRestaurantMenu();
    // }
  },
  middleware: "authentication"
};
</script>

<template>
  <Layout>
    <PageHeader :items="items" :title="title" />

    <div class="row">
      <div class="col-md-12">
        <div class="mb-3">
          <div class="row">
            <div class="col-md-4">
              <label>Select Restaurant Brand </label>
              <multiselect
                v-model="restaurantID"
                :options="restaurants"
                label="name"
                track-by="restID"
                @input="onChangeRestBrand()"
              ></multiselect>
            </div>
            <!-- Cities List-->
            <div class="col-md-2">
              <label>Select City </label>
              <multiselect
                v-model="city"
                :options="cityArr"
                :show-labels="false"
                label="city"
                track-by="city"
                @input="readRestaurantBranchData()"
              ></multiselect>
            </div>
            <!--END Cities List-->
            <div class="col-md-4">
              <label>Select Restaurant Branch </label>
              <multiselect
                v-model="branchID"
                :options="branches"
                label="branchName"
                track-by="restBranchID"
                @input="onchangeBranch()"
              ></multiselect>
            </div>
            <!-- <div class="col-md-4">
                  <button type="button" class="btn btn-themeOrange w-md waves-effect waves-light" @click="applyFilter();" style="margin-top: 27px;">Apply filter</button>
                  </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div
                v-if="this.$storageData.login_type == 1"
                class="col-sm-12 col-md-4"
                style="margin-bottom: 15px;"
              >
                <div class="btn-group" role="group">
                  <button
                    class="btn btn-themeBrown"
                    type="button"
                    @click="downloadSample()"
                  >
                    Excel
                  </button>
                </div>
              </div>
              <div class="col-sm-12 col-md-4">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show &nbsp;&nbsp;
                    <b-form-select
                      v-model="perPage"
                      :options="pageOptions"
                      size="sm"
                      style="margin-left: 5px; margin-right: 5px"
                    ></b-form-select
                    >&nbsp;&nbsp;&nbsp; entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-4 row">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      class="form-control form-control-sm ms-2"
                      placeholder="Search..."
                      type="search"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :current-page="currentPage"
                :fields="fields"
                :filter="filter"
                :filter-included-fields="filterOn"
                :items="tableData"
                :per-page="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                bordered
                fixed-header
                hover
                outlined
                responsive="sm"
                striped
                @filtered="onFiltered"
              >
                <template v-slot:cell(Action)="data">
                  <keep-alive>
                    <router-link
                      :to="{
                        name: 'restaurantMenuDetails',
                        params: {
                          id: data.item.dishID,
                          isEdit: editRight,
                          isDelete: deleteRight
                        }
                      }"
                    >
                      <i
                        class="uil-info-circle"
                        style="font-size: 19px"
                        title="Info"
                      ></i
                    ></router-link>
                  </keep-alive>
                  <!-- <i class="mdi mdi-delete" title="Delete" style="font-size: 19px;"></i> -->
                </template>
                <template v-slot:cell(price)="data">
                  <span v-if="data.item.price">₹{{ data.item.price }}</span>
                </template>

                <template slot="image" slot-scope="data">
                  <img v-bind:src="data.item.name" />
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :per-page="perPage"
                      :total-rows="rows"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
